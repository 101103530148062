import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

const Logo = ({ fill = "#fff" }: { fill?: string }) => {
  return (
    <LogoContainer>
      <svg version="1.1" width="18"
        height="23"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        fill="none" className="Logo__Mobile"
      >
        <path d="M369.025,235.105c17.031-14.694,30.476-33.699,38.434-55.734c5.262-14.568,7.93-29.871,7.93-45.483
			C415.388,60.062,355.326,0,281.5,0h-216c-11.046,0-20,8.954-20,20v472c0,11.046,8.954,20,20,20h259.303
			c21.992,0,44.001-4.558,61.973-12.834c10.033-4.62,14.421-16.499,9.801-26.531c-4.621-10.033-16.498-14.425-26.532-9.801
			c-12.836,5.911-28.903,9.166-45.242,9.166H85.5V40h196c51.77,0,93.888,42.118,93.888,93.888c0,10.966-1.868,21.697-5.551,31.896
			c-13.391,37.08-48.891,61.993-88.337,61.993c-0.965,0-1.906,0.092-2.834,0.224H165.5c-11.046,0-20,8.954-20,20s8.954,20,20,20
			h159.303c56.076,0,101.697,45.894,101.697,102.304c0,11.633-1.67,22.515-4.963,32.341c-3.51,10.473,2.135,21.809,12.608,25.318
			c2.108,0.707,4.251,1.043,6.358,1.043c8.361,0,16.157-5.285,18.961-13.65c4.669-13.932,7.037-29.089,7.037-45.052
			C466.5,307.34,425.567,253.805,369.025,235.105z" fill={fill} />
      </svg>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
        width="250" height="75" 
        viewBox="0 0 250 75"
        fill="none" className="Logo__Desktop"
      >
        <path d="M21.36,34.98l2.52,0c3.12,0,5.28,1.32,5.28,4.152c0,2.088-0.984,3.36-2.88,3.96c2.256,0.648,3.432,1.992,3.432,3.84c0,3.528-2.352,4.968-6.24,4.968l-7.632,0l0-2.04l1.8-0.72l0-11.28l-1.8-0.72l0-2.04z
        M21.36,41.892l1.392,0c1.752,0,2.688-0.744,2.688-2.232c0-1.248-0.888-1.8-2.04-1.8l-2.04,0l0,4.032z 
        M21.36,49.02l1.992,0c1.68,0,2.568-0.768,2.568-2.088c0-1.752-1.008-2.52-3.168-2.52l-1.392,0l0,4.608z 
        M38.46024,33.3l0,7.032c0.696-0.576,2.256-1.632,4.032-1.632c2.448,0,3.768,1.632,3.768,4.32l0,6.36l1.68,0.6l0,1.92l-6.528,0l0-1.92l1.248-0.576l0-5.808c0-1.2-0.48-1.92-1.584-1.92c-1.344,0-2.616,1.272-2.616,1.272l0,6.432l1.272,0.6l0,1.92l-6.792,0l0-1.92l1.92-0.6l0-13.488l-1.92-0.48l0-1.92z 
        M59.23248,51.9l-0.432-1.56s-1.848,1.8-3.888,1.8c-2.328,0-3.744-1.368-3.744-3.768c0-2.832,2.136-3.96,5.784-3.96l1.68,0l0-1.32c0-1.224-0.648-1.752-1.848-1.752c-1.224,0-2.112,0.36-2.112,0.36l-0.36,1.512l-2.16,0l-0.168-3.552s2.904-0.96,5.208-0.96c3.648,0,5.04,1.272,5.04,4.752l0,5.928l1.8,0.432l0,1.92z 
        M58.63248,46.452l-1.368,0c-1.872,0-2.616,0.48-2.616,1.56c0,0.912,0.504,1.44,1.416,1.44c1.272,0,2.568-1.392,2.568-1.392l0-1.608z 
        M66.42072,40.98l0-1.92l6.408-0.12l0,2.04l-1.344,0.24l2.136,6.72l2.04-6.648l-1.392-0.312l0-1.92l6.456-0.12l0,2.04l-1.488,0.312l-3.528,9.84c-1.152,3.168-2.712,6.528-6.528,6.528c-0.816,0-2.328-0.192-2.328-0.192l0.336-2.568l1.56,0c1.92,0,2.808-2.04,3.216-2.976l-4.272-10.56z 
        M91.41696,51.9l-0.432-1.56s-1.848,1.8-3.888,1.8c-2.328,0-3.744-1.368-3.744-3.768c0-2.832,2.136-3.96,5.784-3.96l1.68,0l0-1.32c0-1.224-0.648-1.752-1.848-1.752c-1.224,0-2.112,0.36-2.112,0.36l-0.36,1.512l-2.16,0l-0.168-3.552s2.904-0.96,5.208-0.96c3.648,0,5.04,1.272,5.04,4.752l0,5.928l1.8,0.432l0,1.92z 
        M90.81696,46.452l-1.368,0c-1.872,0-2.616,0.48-2.616,1.56c0,0.912,0.504,1.44,1.416,1.44c1.272,0,2.568-1.392,2.568-1.392l0-1.608z 
        M104.4852,33.3l0,7.032c0.696-0.576,2.256-1.632,4.032-1.632c2.448,0,3.768,1.632,3.768,4.32l0,6.36l1.68,0.6l0,1.92l-6.528,0l0-1.92l1.248-0.576l0-5.808c0-1.2-0.48-1.92-1.584-1.92c-1.344,0-2.616,1.272-2.616,1.272l0,6.432l1.272,0.6l0,1.92l-6.792,0l0-1.92l1.92-0.6l0-13.488l-1.92-0.48l0-1.92z 
        M125.25744,51.9l-0.432-1.56s-1.848,1.8-3.888,1.8c-2.328,0-3.744-1.368-3.744-3.768c0-2.832,2.136-3.96,5.784-3.96l1.68,0l0-1.32c0-1.224-0.648-1.752-1.848-1.752c-1.224,0-2.112,0.36-2.112,0.36l-0.36,1.512l-2.16,0l-0.168-3.552s2.904-0.96,5.208-0.96c3.648,0,5.04,1.272,5.04,4.752l0,5.928l1.8,0.432l0,1.92z 
        M124.65744,46.452l-1.368,0c-1.872,0-2.616,0.48-2.616,1.56c0,0.912,0.504,1.44,1.416,1.44c1.272,0,2.568-1.392,2.568-1.392l0-1.608z 
        M138.20568,38.94l0.216,1.656s1.728-1.896,3.336-1.896c1.32,0,2.232,0.432,2.232,0.432l-0.192,4.608l-2.16,0l-0.36-2.04c-1.392,0-2.592,1.344-2.592,1.344l0,6.336l2.4,0.6l0,1.92l-7.92,0l0-1.92l1.92-0.6l0-7.8l-1.92-0.6l0-1.92z 
        M148.10592,34.98c0-1.368,0.984-2.16,2.064-2.16c1.248,0,2.016,0.648,2.016,2.16c0,1.416-1.032,2.184-2.016,2.184c-1.296,0-2.064-0.768-2.064-2.184z 
        M152.13792,49.38l1.68,0.6l0,1.92l-7.2,0l0-1.92l1.92-0.6l0-7.8l-1.92-0.6l0-1.92l5.52-0.12l0,10.44z"
        fill={fill}
        />
        
        <path d="M164.32616,48.54l0-12.96l-2.04-0.48l0-1.32l4.56-0.12l3,0c4.68,0,7.56,2.88,7.56,8.16s-3.12,8.64-7.92,8.64l-7.2,0l0-1.44z 
        M166.84616,48.54l2.76,0c3,0,5.04-2.04,5.04-6.6c0-4.44-1.92-6.36-4.92-6.36l-2.88,0l0,12.96z 
        M182.29256,41.46l-0.12-3s2.64-0.96,4.68-0.96c2.784,0,4.44,0.96,4.44,3.72l0,7.32l1.92,0.48l0,1.32l-3.72,0.12l-0.504-1.68c-0.816,0.816-2.256,1.92-3.936,1.92c-2.28,0-3.6-1.32-3.6-3.6c0-2.76,2.16-3.72,5.52-3.72l1.92,0l0-1.92c0-1.44-0.84-2.16-2.28-2.16s-2.424,0.36-2.424,0.36l-0.336,1.8l-1.56,0z 
        M188.89256,44.94l-1.68,0c-2.4,0-3.312,0.6-3.312,2.04c0,1.08,0.672,1.8,1.752,1.8c1.8,0,3.24-1.92,3.24-1.92l0-1.92z 
        M198.36296,46.62l0.264,2.064s0.72,0.216,2.016,0.216c1.44,0,2.712-0.408,2.712-1.896c0-2.592-6.696-1.392-6.696-5.76c0-2.496,1.92-3.744,4.392-3.744c1.896,0,4.104,0.72,4.104,0.72l-0.072,3.24l-1.56,0l-0.384-1.872s-0.744-0.288-1.944-0.288c-1.224,0-2.136,0.384-2.136,1.608c0,2.568,6.696,1.584,6.696,5.688c0,2.76-2.4,4.104-5.28,4.104c-2.208,0-3.792-0.528-3.792-0.528l0-3.552l1.68,0z"
        fill="#7A8085"
        />
      </svg>
      
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile { 
    display: none;
  }
  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;