import React from "react";

import { Icon } from '@narative/gatsby-theme-novela/src/types';

const SoundCloudIcon: Icon = ({ fill = "white" }) => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    
    <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M432,240c-5.696,0-11.296,0.608-16.672,1.76C408.128,169.024,346.592,112,272,112c-8.832,0-16,7.168-16,16v256c0,8.832,7.168,16,16,16h160c44.128,0,80-35.872,80-80C512,275.904,476.128,240,432,240z"/>
	  <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M208,144c-8.832,0-16,7.168-16,16v224c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16V160C224,151.168,216.832,144,208,144z"/>
    <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M144,208c-8.832,0-16,7.168-16,16v160c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16V224C160,215.168,152.832,208,144,208z"/>
    <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M80,208c-8.832,0-16,7.168-16,16v160c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16V224C96,215.168,88.832,208,80,208z"/>
    <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M16,256c-8.832,0-16,7.168-16,16v96c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16v-96C32,263.168,24.832,256,16,256z"/>
  </svg>
);

export default SoundCloudIcon;
